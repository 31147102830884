
import React, { useState } from 'react'
import {
    TextField,
    Stack,
    Grid,
    CardContent,
    Card,
    Button,
    Container,
    Checkbox,
    FormControlLabel,
    Typography,
    CircularProgress,
} from '@mui/material'; // @mui
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import api from 'src/config/axios-instance';
import { toast } from 'react-toastify';
import { ENV } from 'src/config/config';

const Delete = () => {

    const [loader, setLoader] = useState(false);

    const AccountSchema = yup.object().shape({
        email: yup.string().required('Email is Required').matches(/^[a-zA-Z0-9_+.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/, 'Invalid email format'),
        checkbox: yup.boolean(),
        consent: yup.boolean(),
    });

    const {
        register: registerEmail,
        handleSubmit: handleSubmitEmail,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(AccountSchema),
    });
    const handleSubmitForm = async (data) => {
        setLoader(true);
        try {
            const response = await api.post(`${ENV.appBaseUrl}/auth/dalete`, { email: data?.email });
            if (response?.data?.success) {
                toast.success(response?.data?.message);
                setLoader(false);
            } else {
                toast.error(response?.data?.message);
                setLoader(false);
            }
        } catch (error) {
            toast.error(error?.response?.data?.message);
            setLoader(false);
        }
    };

    if (loader) {
        return <div className='spinner-wrapper'>
            <CircularProgress value={100} />
        </div>;
    }
    return (
       <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <Container>

                            <form onSubmit={handleSubmitEmail(handleSubmitForm)}>
                                <Card sx={{ p: 2 }}>
                                    <h1>Account Deactivation</h1>
                                    <CardContent>
                                        <Grid container spacing={2}>
                                            {' '}
                                            <Grid item xs={6}>
                                                {' '}
                                                <TextField
                                                    {...registerEmail('email')}

                                                    id="outlined-basic"
                                                    label="Email"
                                                    variant="outlined"
                                                    sx={{ width: '100%' }}
                                                    error={errors.email}
                                                    helperText={errors.email?.message}
                                                />
                                            </Grid>

                                        </Grid>
                                        <Grid item xs={6}>
                                            <div className='delete_checkbox flex items-start flex-coloumn mt-5'>
                                                <Typography variant="h8">checkboxs</Typography>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            {...registerEmail('checkbox')}
                                                            color="primary"
                                                        />
                                                    }
                                                    label="Yes, I want to deactivate my account"
                                                />
                                            </div>
                                            <div className='delete_checkbox flex items-start flex-coloumn mt-5'>
                                                <Typography variant="h8">Consent</Typography>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            {...registerEmail('consent')}
                                                            color="primary"
                                                        />
                                                    }
                                                    label="I consent to the storage of my data according to the Privacy Policy"
                                                />

                                            </div>
                                        </Grid>
                                    </CardContent>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            marginTop: '16px',
                                            marginRight: '22px',
                                        }}
                                    >
                                        <Stack spacing={2} direction="row">
                                            <Button variant="contained" type="submit" color="primary">
                                                Deactivate Account
                                            </Button>
                                        </Stack>
                                    </div>
                                </Card>

                            </form>
                        </Container>
                    </Grid>
                </Grid>
    )
}

export default Delete