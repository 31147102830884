import React, { lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';

// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
import { checkPermission } from './utils/Permissions';
import PrivateRoute from './privateRoute';
import PrivecyPolicy from './pages/privecy-policy/privecy-policy';
import Delete from './pages/users/Delete';

// Lazy loading is used to optimize performance.
const DashboardAppPage = lazy(() => import('./pages/DashboardAppPage'));
const UserPage = lazy(() => import('./pages/users/List'));
const CreateUser = lazy(() => import('./pages/users/Create'));
const EditUser = lazy(() => import('./pages/users/Edit'));
const RoleList = lazy(() => import('./pages/roles/List'));
const CreateRoles = lazy(() => import('./pages/roles/Create'));
const CreateEditRole = lazy(() => import('./pages/roles/Edit'));
const EmailTemplatePage = lazy(() => import('./pages/email-template/List'));
const CreateEmailTemplatePage = lazy(() => import('./pages/email-template/Create'));
const EditEmailTemplatePage = lazy(() => import('./pages/email-template/Edit'));
const Profile = lazy(() => import('./pages/Profile'));
const LogHistory = lazy(() => import('./pages/log-history/List'));
const ViewLog = lazy(() => import('./pages/log-history/View'));
const Integration = lazy(() => import('./pages/integration/Add'));
const ContactUsQuery = lazy(()=>import("./pages/contact-us-query/List"))
const EditContactUsQuery = lazy(() => import('./pages/contact-us-query/Edit'));
const CreateContactUsQuery = lazy(() => import('./pages/contact-us-query/Create'));
const Setting = lazy(() => import('./pages/Setting'));
const LoginPage = lazy(() => import('./pages/LoginPage'));
const Page404 = lazy(() => import('./pages/Page404'));

// ----------------------------------------------------------------------

export default function Router() {
    /**
     * Create routes and Implement permissions on routes
     *
     * Author: Ali Haider
     * Date: 013 Sep, 2023
     *
     * Update: change the permission on all routes
     *
     * Update2: Add private route middleware to protect our routes and remove Suspense from all routes
     */

    const isAuthenticated = !!localStorage.getItem('token');

    const routes = useRoutes([
        {
            path: '/',
            element: <DashboardLayout />,
            children: [
                { element: <Navigate to="/dashboard" />, index: true },
                {
                    path: 'dashboard',
                    element: (
                        <PrivateRoute
                            isAuthenticated={isAuthenticated}
                            element={
                                checkPermission('View-Dashboard') ? <DashboardAppPage /> : <Navigate to="/403" />
                            }
                        />
                    ),
                },
                {
                    path: 'users',
                    element: (
                        <PrivateRoute
                            isAuthenticated={isAuthenticated}
                            element={checkPermission('View-Users') ? <UserPage /> : <Navigate to="/403" />}
                        />
                    ),
                },
                {
                    path: 'users/create',
                    element: (
                        <PrivateRoute
                            isAuthenticated={isAuthenticated}
                            element={checkPermission('Create-User') ? <CreateUser /> : <Navigate to="/403" />}
                        />
                    ),
                },
                {
                    path: 'users/edit/:id',
                    element: (
                        <PrivateRoute
                            isAuthenticated={isAuthenticated}
                            element={checkPermission('Update-User') ? <EditUser /> : <Navigate to="/403" />}
                        />
                    ),
                },
                {
                    path: 'roles',
                    element: (
                        <PrivateRoute
                            isAuthenticated={isAuthenticated}
                            element={checkPermission('View-Role') ? <RoleList /> : <Navigate to="/403" />}
                        />
                    ),
                },
                {
                    path: 'roles/create',
                    element: (
                        <PrivateRoute
                            isAuthenticated={isAuthenticated}
                            element={checkPermission('Create-Role') ? <CreateRoles /> : <Navigate to="/403" />}
                        />
                    ),
                },
                {
                    path: 'roles/edit/:id',
                    element: (
                        <PrivateRoute
                            isAuthenticated={isAuthenticated}
                            element={checkPermission('Update-Role') ? <CreateEditRole /> : <Navigate to="/403" />}
                        />
                    ),
                },
                {
                    path: 'email-templates',
                    element: (
                        <PrivateRoute
                            isAuthenticated={isAuthenticated}
                            element={
                                checkPermission('View-Email-Template') ? (
                                    <EmailTemplatePage />
                                ) : (
                                    <Navigate to="/403" />
                                )
                            }
                        />
                    ),
                },
                {
                    path: 'email-templates/create',
                    element: (
                        <PrivateRoute
                            isAuthenticated={isAuthenticated}
                            element={
                                checkPermission('Create-Email-Template') ? (
                                    <CreateEmailTemplatePage />
                                ) : (
                                    <Navigate to="/403" />
                                )
                            }
                        />
                    ),
                },
                {
                    path: 'email-templates/edit/:id',
                    element: (
                        <PrivateRoute
                            isAuthenticated={isAuthenticated}
                            element={
                                checkPermission('Update-Email-Template') ? (
                                    <EditEmailTemplatePage />
                                ) : (
                                    <Navigate to="/403" />
                                )
                            }
                        />
                    ),
                },
               {
                    path: 'profile',
                    element: (
                        <PrivateRoute
                            isAuthenticated={isAuthenticated}
                            element={checkPermission('View-Profile') ? <Profile /> : <Navigate to="/403" />}
                        />
                    ),
                },
                {
                    path: 'logs',
                    element: (
                        <PrivateRoute
                            isAuthenticated={isAuthenticated}
                            element={checkPermission('View-Notification') ? <LogHistory /> : <Navigate to="/403" />}
                        />
                    ),
                },
                {
                    path: 'logs/:id',
                    element: (
                        <PrivateRoute
                            isAuthenticated={isAuthenticated}
                            element={checkPermission('View-Notification') ? <ViewLog /> : <Navigate to="/403" />}
                        />
                    ),
                },
                {
                    path: 'integration',
                    element: (
                        <PrivateRoute
                            isAuthenticated={isAuthenticated}
                            element={checkPermission('View-Integration') ? <Integration /> : <Navigate to="/403" />}
                        />
                    ),
                },
                {
                    path: 'contact-us-query',
                    element: (
                        <PrivateRoute
                            isAuthenticated={isAuthenticated}
                            element={checkPermission('View-Contact-Query') ? <ContactUsQuery /> : <Navigate to="/403" />}
                        />
                    ),
                },
                {
                    path: 'contact-us-query/edit/:id',
                    element: (
                        <PrivateRoute
                            isAuthenticated={isAuthenticated}
                            element={checkPermission('Update-Contact-Query') ? <EditContactUsQuery /> : <Navigate to="/403" />}
                        />
                    ),
                },
                {
                    path: 'contact-us-query/create',
                    element: (
                        <PrivateRoute
                            isAuthenticated={isAuthenticated}
                            element={checkPermission('Create-User') ? <CreateContactUsQuery /> : <Navigate to="/403" />}
                        />
                    ),
                },
                {
                    path: 'setting',
                    element: (
                        <PrivateRoute
                            isAuthenticated={isAuthenticated}
                            element={checkPermission('Create-Settings') ? <Setting /> : <Navigate to="/403" />}
                        />
                    ),
                },
            ],
        },
        {
            path: 'login',
            element: <LoginPage />,
        },
        {
            element: <SimpleLayout />,
            children: [
                { element: <Navigate to="/login" />, index: true },
                {
                    path: '404',
                    element: <Page404 />,
                },
                { path: '*', element: <Navigate to="/404" /> },
            
            ],
        },
        {
            path: '/privecy-policy',
            element: <PrivecyPolicy />,
        },
        {
            path: '/deactivate-account',
            element: <Delete />,
        },
        {
            path: '*',
            element: <Navigate to="/404" replace />,
        },
    ]);

    return routes;
}
