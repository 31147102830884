// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
    <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1, color: '#fff' }} />
);

/**
 * Implement permissions for nav
 *
 * Author: Ali Haider
 * Date: 15 Sep, 2023
 */
const navConfig = [
    {
        title: 'Dashboard',
        path: '/dashboard',
        icon: icon('ic_analytics'),
        name: 'View-Dashboard',
    },
    {
        title: 'Users Management',
        icon: icon('users-management'),
        name: 'View-Users-Management',
        submenu: [
            {
                title: 'Users',
                path: '/users',
                icon: icon('ic_user'),
                name: 'View-Users',
            },
            {
                title: 'Roles',
                path: '/roles',
                icon: icon('roles-permission'),
                name: 'View-Role',
            },
        ],
        },
    {
        title: 'Email Templates',
        path: '/email-templates',
        icon: icon('email'),
        name: 'View-Email-Template',
    },
    {
        title: 'Log History',
        path: '/logs',
        icon: icon('list'),
        name: 'View-Log-History',
    },
    {
        title: 'Integration',
        path: '/integration',
        icon: icon('integration'),
        name: 'View-Integration',
    },
    {
        title: 'Contact Us Query',
         path: '/contact-us-query',
        icon: icon('chat'),
        name: 'View-Contact-Query',
    },
    {
        title: 'Setting',
        path: '/setting',
        icon: icon('settings'),
        name: 'Create-Settings',
    },
];

export default navConfig;
